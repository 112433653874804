/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled from '@emotion/styled'
import SEO from "./seo"
import Header from "./header"
import "./layout.css"

const Main = styled.main`
  position: relative;
`

const Layout = ({ children }) => {
  return (
    <>
      <SEO
        title="Stream Team | Social Media Marketing"
        description="Stream Team uses decades of sales, marketing and communications experience to design and create unparalleled social media experiences for your customers."
        keywords={[ `Targeted Marketing Strategies`,
          `Market Research`,
          `Real World Business Advice`,
          `Campaign Designs`,
          `Improved website Accessibility` ]}
      />
      <Header></Header>
      <Main>{children}</Main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
