import React from "react"
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEnvelope} from "@fortawesome/free-solid-svg-icons"
import { useStaticQuery, graphql } from 'gatsby'
import Logo from '../images/stream-team-logo@2x.png'
import Img from 'gatsby-image'

const HeaderSection = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
  left: 0;
  img {
    display: block;
    margin-bottom: 0;
  }
`
const Title = styled.a`
  color: var(--color-orange);
  display: inline-block;
  font-size: 36px;
  font-weight: 600;
  font-family: var(--font-heading);
  line-height: 1;
  padding-left: 16px;
  padding-top: 20px;
  background: #1e2026;
  padding-right: 16px;
  padding: 20px 18px 25px;
  max-width: 280px;
`
const LinkContainer = styled.div`
  margin-top: 10px;
  margin-right: 10px;
  background: #1e1f27;
  a {
    padding: 6px 12px 10px;
    font-size: 30px;
    font-family: Stolzl;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    letter-spacing: 0.025em;
    line-height: 1;
    display: block;
    margin-bottom: 0;
  }
`
const EmailLink = styled.a`
  color: var(--color-orange);
  &:hover {
    color: var(--color-green-dark);
  }
`
const PhoneLink = styled.a`
  color: var(--color-orange);
  &:hover {
    color: var(--color-green-dark);
  }
`

function Header () {

  return (
  
    <HeaderSection>
      <Title title="Stream Team">
        <img alt="Stream Team Logo" src={Logo} />
      </Title>
      <LinkContainer tabindex="-1">
        <EmailLink aria-label="Phone Number" tabindex="-1" target="_blank" href="tel:506-425-4400">
           <FontAwesomeIcon icon={faEnvelope} />
        </EmailLink>
      </LinkContainer>
    </HeaderSection>

  )
} 

export default Header
